.about-wrapper2{
	.container{
		z-index: 2;
	}
	&:after{
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
		background-color: #fff;
		width: 100%;
		height: 100px;
	}
}