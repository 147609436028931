.dz-card{
	&.style-5{
		border: 1px solid var(--bs-gray-300);
		overflow: hidden;
		border-radius: 14px;
		overflow:hidden;
		margin: 0;
		.dz-media{
			border-radius: unset;
			.dz-badge-list{
				position:absolute;
				bottom:25px;
				left:20px;
				@include respond('tab-port'){
					bottom:15px;
				}
			}
			.dz-badge{
				background-color:var(--primary);
				color:#fff;
				border-radius:3px;
				padding: 6px 15px;
				font-size: 12px;
				text-transform:uppercase;
			}
		}
		.author-wrappper .author-media {
			height: 24px;
			width: 24px;
			min-width: 24px;
		}
		.dz-info{
			padding:25px 30px;
			background-color: #fff;
			
			@include respond('phone'){
				padding:20px;
			}
			.dz-category{
				li{
					a{
						font-size: 14px;
						font-weight: 600;
					}
				}
			}
			.dz-meta{
				margin: 10px 0;
				display: flex;
				align-items: center;
				span{
					font-size: 14px;
					font-weight: 400;
					color: var(--title);
				}
				.author-media{
					display: flex; 
					align-items: center; 
				}
				.dz-date{
					margin-left: 20px;
					i{
						color: var(--primary);
					}
				}
			}
			p{
				margin-bottom: 0!important;
			}
		}
	}
}