// TESTIMONIALS
.testimonial-pic {
    background: #FFF;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #FFF;
	&.radius {
		border-radius: 100%;
		-webkit-border-radius: 100%;
		img {
			width: 100%;
			height: 100%;
			border-radius: 100%;
			-webkit-border-radius: 100%;
		}
	}
	&.shadow {
		-webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
		box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
	}
}
.testimonial-swiper{
	.swiper-pagination{
		bottom: 0;
	}
	@include respond('phone-land'){
		margin-left:-15px;
		margin-right:-15px;
	}
}
.quote-left,
.quote-right{
	position: relative;
	&:before {
		font-family: "Flaticon";
		position: absolute;
		z-index: 10;
		height: 36px;
		width: 36px;
		line-height: 36px;
		background-color: var(--primary);
		color: $white;
		border-radius: 30px;
		text-align: center;
		font-size: 18px;
	}
}
.quote-left{
	&:before {
		top: 15px;
		content: "\f115";
		left: 0;
	}
}
.quote-right{
	&:before {
		top: 15px;
		content: "\f11e";
		right: 0;
	}
}
.testimonial-text{
    position: relative;
	font-size:16px;
	font-weight: 400;
	p:last-child {
		margin: 0;
	}
}
.testimonial-detail {
    padding: 5px;
}
.testimonial-position {
    font-size: 15px;
}
.testimonial-name,
.testimonial-position {
    display: block;
}
.testimonial-bg {
    color: #fff;
}
.star-rating{
	li{
		display: inline-block;
	}
}