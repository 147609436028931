.dz-card{
	&.style-6{
		border-radius: var(--border-radius-base);
		overflow: hidden;
		transition: all 0.8s;
		border: 1px solid transparent;
		background-color: var(--bs-gray-200);
		@include transitionMedium;
		.dz-info{
			padding:30px 25px;
			@include respond('phone'){
				padding: 25px 20px;
			}
			p {
				margin-bottom:0;
			}
		}
		.dz-meta{
			margin-bottom: 20px;
			ul{
				li{
					font-size: 14px;
					font-weight: 500;
					position: relative;
					margin-right: 30px;

					&.dz-user{
						color: var(--title);
						font-weight: 500;
						
						span{
							color: var(--primary);
						}
					}
					&.dz-date{
						color: var(--title);				
						font-weight: 500;
					}
					&:last-child{
						margin-right: 0;
					}
					&:not(:last-child):after{
						content: "";
						height: 6px;
						width: 6px;
						position: absolute;
						right: -20px;
						top: 50%;
						border-radius: 50%;
						background-color: var(--primary);
						transform: translate(0%, -50%);
					}
				}
			}
		}
		.dz-media{
			overflow: hidden;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: -100%;
				left: 0;
				background-color: var(--primary);
				opacity: 0.9;
				@include transitionSlow;
			}
			border-radius: unset;
			.dz-badge-list{	
				position:absolute;
				top:20px;
				left:20px;
				z-index: 1;
			}
			.dz-badge{
				background-color:var(--secondary);
				color: var(--title);
				border-radius:var(--border-radius-base);
				font-size: 15px;
				font-weight: 500;
				text-transform:uppercase;
				display: block;
				@include transitionSlow;
				&:hover{
					background-color: rgba(255 ,255 ,255 ,1);
					color: var(--primary);
				}
			}
			.btn{
				position: absolute;
				top: -50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include transitionSlow;
				z-index:1;
			}
		}
		@include respond('phone-land'){
			&.blog-half{
				display: block;
				.dz-media {
					max-width: 100%;
					min-width: 100%;
				}
			}
		}
		&:hover{
			.dz-media{
				&:after{
					bottom: 0;
				}
			}
			.btn{
				top: 50%;
			}
			.dz-badge{
				background-color: rgba(255 ,255 ,255 ,0.2);
				color: #fff;
			}
		}
	}
}