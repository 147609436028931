.icon-bx-wraper{
	// Style 1
	&.style-1{
		background-color:#fff;
		padding: 30px;
		border-radius: 14px;
		border:1px solid lightgrey;
		@include transitionMedium;
		overflow: hidden;
		margin-bottom: 30px;
		z-index: 1;
		
		&:before{
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			color: var(--primary);
			background-color: var(--secondary);
			width: 100%;
			height: 100%;
			@include transitionSlow;
			z-index: -1;
			opacity: 0;
		}
		.icon-content{
			p{
				margin-top: 10px;
				margin-top: 10px;
				@include transitionSlow;
			}
		}
		.icon-cell{
			position: relative;
			
			&:after{
				content: "";
				position: absolute;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-color: var(--primary);
				top: 15%;
				left: 50%;
				z-index: -1;
				opacity: 0.1;
				@include transitionSlow;
			}
		}
		[class*="icon-bx"]{
			transform:scale(1)rotate(0deg);
			@include transitionMedium;
		}
		i {
			@include transitionMedium;
			display: inline-block;
		}
		&:hover{
			box-shadow: 1px 10px 30px 0px rgba(255,116,104,0.3);
			&:before{
				opacity: 1;
			}
			.icon-lg{
				.icon-cell{
					i{
						color: #fff;
						animation: shake 0.5s;
						animation-iteration-count: infinite;						
					}
				}
			}
			.icon-content{
				.dz-tilte,
				.dz-tilte a{
					color: #fff;
				}
				p{
					color: #fff;					
				}
			}
			.icon-cell{
				&:after{
					background-color: #fff;
				}
			}
		}
		@include respond('phone-land'){
			padding: 25px 30px;
		}
		@include respond('phone'){
			padding: 20px;
			
			.icon-cell:after{
				width: 40px;
				height: 40px;
			}
			.dz-tilte{
				font-size: 16px;
			}
		}
	}
	
	// Style 2
	&.style-2{
		background:#fff;
		width: 100%;
		overflow:hidden;
		text-align:left;
		display:inline-flex;
		align-items:center;
		border: 1px solid rgb(238, 238, 238);
		border-radius:var(--border-radius-base);
		padding: 30px;
		z-index:1;
		@include transitionMedium;
		.icon-bx{
			height:76px;
			width:76px;
			text-align: center;
			line-height:76px;
			position:relative;
			@include transitionMedium;
			z-index:1;
			.ico-bg-shape{
				height:100%;
				width:100%;
				position:absolute;
				top:0;
				left:0;
				overflow:visible;
				z-index: -1;
				filter: drop-shadow(0px 4px 6px var(--rgba-primary-4));
			}
		}
		.dz-title,
		.dz-title a{
			font-weight:700;
			@include transitionMedium;
		}
		.icon-cell{
			margin-right: 25px;
			background-color: var(--rgba-primary-1);
			display: flex;
			height: 70px;
			width: 70px;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
		}
		i {
			height: 40px;
			font-size: 36px;
			border-radius: 100%;
			width: 40px;
			color:var(--primary);
			text-align: center;
			line-height: 40px;
			@include transitionMedium;
		}
		&:hover{
			border: 1px solid #fff;
			box-shadow: 0px 0 60px rgba(0, 0, 0, 0.09);
			.icon-cell{
				background-color: var(--primary);
				i{
					color: #fff;
				}
			}
		}
		@include custommq($max: 1199px) {
			font-size:14px;
			padding: 20px 15px;
		}
		@include respond('phone'){
			text-align: center;
			display: block;
			.icon-cell{
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
	
	// Style 3
	&.style-3{
		background:#fff;
		width: 100%;
		height: 100%;
		overflow:hidden;
		text-align:left;
		display:inline-flex;
		align-items:center;
		border: 1px solid rgb(238, 238, 238);
		border-radius:var(--border-radius-base);
		padding: 25px 25px;
		z-index:1;
		@include transitionMedium;
		
		&:hover{
			border: 1px solid #fff;
			box-shadow: 0px 0 45px rgba(0, 0, 0, 0.08);
			
			.icon-cell{
				i {
					animation: toTopFromBottom 0.8s forwards;
				}
			}
		}
		@include custommq($max: 1199px) {
			font-size:14px;
			padding: 20px 15px;
		}
		.icon-bx{
			height:76px;
			width:76px;
			text-align: center;
			line-height:76px;
			position:relative;
			@include transitionMedium;
			z-index:1;
			.ico-bg-shape{
				height:100%;
				width:100%;
				position:absolute;
				top:0;
				left:0;
				overflow:visible;
				z-index: -1;
				filter: drop-shadow(0px 4px 6px var(--rgba-primary-4));
			}
		}
		.dz-title,
		.dz-title a{
			font-weight:700;
			@include transitionMedium;
		}
		.icon-cell{
			margin-right: 15px;
			background-color: var(--rgba-primary-1);
			display: flex;
			height: 65px;
			width: 65px;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
		i {
			height: 40px;
			font-size: 25px;
			border-radius: 100%;
			width: 40px;
			color:var(--primary);
			text-align: center;
			line-height: 40px;
			@include transitionMedium;
		}
	}
	
	// Style 4
	&.style-4{
		padding: 30px;
		border-radius: 14px;
		background-color:#fff;
		border:1px solid #fff;
		@include transitionMedium;
		overflow: hidden;
		margin-bottom: 30px;
		z-index: 1;
		
		&.transparent{
			background-color: rgba(255, 255, 255, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.2);
			color: #fff;
			p{
				color: #fff;
			}
		}
		
		p{
			color: var(--title);
			font-weight: 400;
			@include transitionSlow;
			font-size: 18px;
			line-height: 1.6;
		}
		
		.separator{
			position: relative;
			height: 5px;
			background-color: var(--secondary);
			@include transitionSlow;
			border-radius: 14px;
			width: 80px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
		}
		[class*="icon-bx"]{
			transform:scale(1)rotate(0deg);
			@include transitionMedium;
			
			i {
				@include transitionMedium;
			}
			svg{
				path{
					fill: var(--primary);
					@include transitionMedium;
					
				}
				.secondary{
					fill: var(--secondary);						
				}
			}
		}
		@include respond('phone-land'){
			padding: 15px;
			p{
				font-size: 16px;
			}
		}
		@include respond('phone'){
			[class*="icon-bx"]{
				margin-bottom: 10px;
				svg{
					width: 40px;
					height: 40px;
				}
			}
			.separator {
				height: 3px;
				width: 60px;
			}
		}
		
		&:hover{
			box-shadow: 1px 10px 30px 0px rgba(255,116,104,0.3);
			background-color: var(--primary);
			border-color: var(--primary);
			
			.dz-tilte,
			.dz-tilte a{
				color: #fff;
			}
			p{
				color: #fff;					
			}
			[class*="icon-bx"]{
				svg path{
					fill: var(--secondary);
				}
			}
		}
	}
	
	&.style-5{
		padding: 20px;
		
		@include respond('phone'){
			padding: 0;
		}
		.icon-content p{
			font-weight: 500;
			max-width: 280px;
			font-size: 13px;
			margin-left: auto;
			margin-right: auto;
			@include transitionSlow;
		}
		.title{
			margin-bottom: 10px;
		}
		.icon-lg{
		    position: relative;
			border: 2px dashed var(--secondary);
			display: flex;
			padding: 25px;
			border-radius: 50%;
			width: 120px;
			height: 120px;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			
			.badge{
				position: absolute;
				background-color: var(--primary);
				border-radius: 50%;
				width: 35px;
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				bottom: -3px;
				right: -3px;
				padding: 0;
				line-height: 35px;
				@include transitionSlow;
			}
		}
		
		&:hover{
			.icon-cell{
				animation: shake 0.5s;
				animation-iteration-count: infinite;
			}
			.badge{
				background-color: var(--secondary);
				color: var(--title);
			}
		}
	}
}

@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%); }
	50% {
		opacity: 0;
		-webkit-transform: translateY(100%); }
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}