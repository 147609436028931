// Font size
.font-12{
    font-size: 12px !important;
}
.font-13{
    font-size: 13px !important;
}
.font-14 {
    font-size: 14px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18 {
    font-size: 18px !important;
}
.font-22{
    font-size: 22px !important;
}
.font-24{
    font-size: 24px !important;
}
.font-26 {
    font-size: 26px !important;
}

$value: 20;
$x: 5;
$y: px;
@for $i from 0 through $value{
    .font-#{$i*$x}{
        font-size: ($x*$i) + $y !important;
    }
}

// Font Weight
$value: 9;
$x: 100;
@for $i from 0 through $value{
    .font-w#{$i*$x}{
        font-weight: ($x*$i) + !important;
    }
}

// Letter Spacing
$value: 5;
$x: 1;
@for $i from 0 through $value{
    .ls-#{$i*$x}{
        letter-spacing: ($x*$i) + px;
    }
}

$value: 20;
$x: 5;
$y: px;
@for $i from 0 through $value{
	// Padding Value
	.p-a#{$i*$x}{
		padding: ($x*$i) + px;
	}
	.p-t#{$i*$x}{
		padding-top: ($x*$i) + px;
	}
	.p-b#{$i*$x}{
		padding-bottom: ($x*$i) + px;
	}
	.p-l#{$i*$x}{
		padding-left: ($x*$i) + px;
	}
	.p-r#{$i*$x}{
		padding-right: ($x*$i) + px;
	}
	.p-lr#{$i*$x}{
		padding-left: ($x*$i) + px;
		padding-right: ($x*$i) + px;
	}
	.p-tb#{$i*$x}{
		padding-bottom: ($x*$i) + px;
		padding-top: ($x*$i) + px;
	}
	
	// Margin Value
	.m-a#{$i*$x}{
		margin: ($x*$i) + px;
	}
	.m-t#{$i*$x}{
		margin-top: ($x*$i) + px;
	}
	.m-b#{$i*$x}{
		margin-bottom: ($x*$i) + px;
	}
	.m-l#{$i*$x}{
		margin-left: ($x*$i) + px;
	}
	.m-r#{$i*$x}{
		margin-right: ($x*$i) + px;
	}
	.m-lr#{$i*$x}{
		margin-left: ($x*$i) + px;
		margin-right: ($x*$i) + px;
	}
	.m-tb#{$i*$x}{
		margin-bottom: ($x*$i) + px;
		margin-top: ($x*$i) + px;
	}
}

@media only screen and (max-width: 1200px) {
	@for $i from 0 through $value{
		// Margin Value
		.m-lg-t#{$i*$x}{
			margin-top: ($x*$i) + px;
		}
		.m-lg-b#{$i*$x}{
			margin-bottom: ($x*$i) + px;
		}
	}
	.m-lg-r0 {margin-right:0;}
	.m-lg-l0 {margin-left:0;}
}
@media only screen and (max-width: 991px) {
	@for $i from 0 through $value{
		// Margin Value
		.m-md-t#{$i*$x}{
			margin-top: ($x*$i) + px;
		}
		.m-md-b#{$i*$x}{
			margin-bottom: ($x*$i) + px;
		}
	}
	.m-md-r0 {margin-right:0;}
	.m-md-l0 {margin-left:0;}
}
@media only screen and (max-width: 767px) {
	@for $i from 0 through $value{
		// Margin Value
		.m-sm-t#{$i*$x}{
			margin-top: ($x*$i) + px;
		}
		.m-sm-b#{$i*$x}{
			margin-bottom: ($x*$i) + px;
		}
	}
}
@media only screen and (max-width: 576px) {
	@for $i from 0 through $value{
		// Margin Value
		.m-xs-t#{$i*$x}{
			margin-top: ($x*$i) + px;
		}
		.m-xs-b#{$i*$x}{
			margin-bottom: ($x*$i) + px;
		}
	}
}

// BOX MAX-WIDTH CSS
.max-w50 {
    max-width: 50px;
}
.max-w60 {
    max-width: 60px;
}
.max-w80 {
    max-width: 80px;
}
.max-w100 {
    max-width: 100px;
}
.max-w200 {
    max-width: 200px;
}
.max-w300 {
    max-width: 300px;
}
.max-w400 {
    max-width: 400px;
}
.max-w500 {
    max-width: 500px;
}
.max-w600 {
    max-width: 600px;
}
.max-w700 {
    max-width: 700px;
}
.max-w800 {
    max-width: 800px;
}
.max-w900 {
    max-width: 900px;
}
.max-w1000 {
    max-width: 1000px;
}

// BACKGROUND IMAGE
.bg-img-fix {
    background-attachment: fixed;
	background-size: cover;
}
.full-img{
	width:100%;
}

.sticky-top{
	top:120px;
}
.page-title {
    padding : 65px 0px 45px;
    position: relative;
    background         : $light;
    background-size    : cover;
    background-position: center;

    p {
        font-size     : 20px;
        font-weight  : 500;
        margin-bottom : 5px;
        color         : #7272A8;
        text-transform: capitalize;
		@include respond('phone'){
			font-size:16px;
		}
    }

    h3 {
        font-size     : 40px;
        line-height   : 1.2;
        font-weight  : 600;
        text-transform: capitalize;
		@include respond('phone'){
			font-size: 28px;
		}
    }
}
.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
section{
    position: relative;
	z-index: 1;
}

// rounded
.rounded-xl{
	border-radius: 50% !important;
}
.rounded-lg{
	border-radius: 20px !important;
}
.rounded-md{
	border-radius: 10px !important;
}
.rounded-sm{
	border-radius: 5px !important;
}

// text color
.text-orange{
	color: $orange;
}
.text-yellow{
	color: $yellow;
}
.text-skyblue{
	color: $green;
}
.text-red{
	color: $red;
}
.text-green{
	color: $green;
}
.text-blue{
	color: $blue;
}

// bg color
.bg-gray{
	background-color: $gray;
}
.bg-orange{
	background-color: $orange;
}
.bg-yellow{
	background-color: $yellow;
}
.bg-skyblue{
	background-color: $skyblue;
}
.bg-red{
	background-color: $red;
}
.bg-green{
	background-color: $green;
}
.bg-blue{
	background-color: $blue;
}
.bg-pink {
	background-color:$pink;
}
.text-black{
	color:#071c35;
}
.bg-purple{
	background-color:$purple;
}


// Scale
.scale8{
	transform:scale(1.85);
	-moz-transform:scale(1.85);
	-webkit-transform:scale(1.85);
	-ms-transform:scale(1.85);
	-o-transform:scale(1.85);
}
.scale7{
	transform:scale(1.7);
	-moz-transform:scale(1.7);
	-webkit-transform:scale(1.7);
	-ms-transform:scale(1.7);
	-o-transform:scale(1.7);
}
.scale5{
	@include transformScaleMD;
}
.scale3{
	transform:scale(1.3);
	-moz-transform:scale(1.3);
	-webkit-transform:scale(1.3);
	-ms-transform:scale(1.3);
	-o-transform:scale(1.3);
}
.scale2{
	@include transformScaleSM;
}
.scale08{
	@include transformScaleXS;
}
.scale05{
	@include transformScaleXS1;
}
.tl{
	transform-origin: left;
	-moz-transform-origin: left;
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	-o-transform-origin: left;
}
.shadow-none{
	box-shadow: unset;
}
.separator{
	height: 1px;
    width: 100%;
    background: lightgrey;	
}
.separator-2{
	width: 55px;
	height: 5px;
	border-radius: 5px;
}