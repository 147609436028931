.dz-card{
	&.style-1{
		border-radius: 15px;
		overflow: hidden;
		transition: all 0.8s;
		border: 1px solid var(--bs-gray-200);
		@include transitionMedium;
		background-color: #fff;
		
		.dz-info{
			padding:30px 25px;
			@include respond('phone'){
				padding: 20px 15px;
				padding-top: 20px;
			}
			p {
				margin-bottom:30px;
				@include respond('tab-port'){
					margin-bottom:20px;
				}
			}
		}
		.dz-media{
			overflow: hidden;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: -100%;
				left: 0;
				background-color: var(--primary);
				opacity: 0.9;
				@include transitionSlow;
			}
			border-radius: unset;
			.dz-badge-list{
				position:absolute;
				bottom:20px;
				left:20px;
				z-index: 1;
			}
			.dz-badge{
				background-color:var(--secondary);
				color:#fff;
				border-radius:var(--border-radius-base);
				font-size: 12px;
				text-transform:uppercase;
				display: block;
				@include transitionSlow;
				&:hover{
					background-color: rgba(255 ,255 ,255 ,1);
					color: var(--primary);
				}
			}
			.btn{
				position: absolute;
				top: -50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include transitionSlow;
				z-index:1;
			}
		}
		&:hover{
			.dz-media{
				&:after{
					bottom: 0;
				}
			}
			.btn{
				top: 50%;
			}
			.dz-badge{
				background-color: rgba(255 ,255 ,255 ,0.2);
				color: #fff;
			}
		}
	}
}
.swiper-container {
	.dz-card {
		&.style-1 {
			margin: 15px;
		}
	}
}
