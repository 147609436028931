.badge{
	line-height: 1.2;
    border-radius: var(--border-radius-base);
    font-weight: 600;
    padding: 6px 12px;
    border: 0.0625rem solid transparent;
    font-size: 13px;
}

.badge-rounded{
    border-radius: 1.25rem;
    padding:0.1875rem 0.8125rem ;
}
.badge-circle{
    border-radius: 6.25rem;
    padding: 0.1875rem 0.4375rem;
}

// Badge Outline
.badge-outline-primary{
    border: 0.0625rem solid var(--primary);
    color: var(--primary);
}
.badge-outline-secondary{
    border: 0.0625rem solid $secondary;
    color: $secondary;
}
.badge-outline-success{
    border: 0.0625rem solid $success;
    color: $success;
}
.badge-outline-info{
    border: 0.0625rem solid $info;
    color: $info;
}
.badge-outline-warning{
    border: 0.0625rem solid $warning;
    color: $warning;
}
.badge-outline-danger{
    border: 0.0625rem solid $danger;
    color: $danger;
}
.badge-outline-light{
    border: 0.0625rem solid $border;
    color: $dark;
}
.badge-outline-dark{
    border: 0.0625rem solid $dark;
    color: $dark;
}


// sizes
.badge-lg {
    padding: 6px 14px;
    font-size: 14px;
    line-height: 1.4;
	&:hover{
		color: #fff;
	}
}
.badge-sm {
	font-size: 12px;
    padding: 4px 8px;
}


// color
.badge-success {
    background-color: $success;
}
.badge-secondary {
    background-color: $secondary;
}
.badge-info {
    background-color: $info;
}
.badge-primary {
    background-color: var(--primary);
}
.badge-warning {
    background-color: $warning;
}
.badge-danger {
    background-color: $danger;
}
.badge-dark {
    background-color: $dark;
}
.badge-light {
    background-color: $light;
	border-color: $border;
    color: $dark;
}
