.find-bx{
	border-radius: var(--border-radius-base);
    margin-bottom: 20px;
    max-width: 1280px;
    padding: 20px 30px 20px 0;
    margin-top: -50px;
	box-shadow: 0px 25px 50px 0px rgba(0,0,0 ,0.1);
	position: relative;
	z-index: 99;
	
	.btn-light{
		&:hover{
			color: var(--title)!important;
		}
	}
	.row > *{
		border-right: 1px solid rgba(0,0,0,0.1);
		
		&:last-child{
			border-right: 0;
		}
	}
	.input-group-text{
		font-size: 24px;
		padding-right: 5px;
	}
	.default-select{
		border: 0!important;
		padding: 0;
		
		.dropdown-toggle{
			background-color: transparent!important;
			color: var(--title);
			font-size: 18px;
			font-weight: 600;
			height: 60px;
			line-height: 60px;
			padding: 0 10px 0 40px;
			border: 0!important;
			@include respond('tab-port'){
				padding:0 15px;				
				font-size: 16px;
			}
			@include respond('phone'){
				padding: 0 10px;
				font-size: 15px;
			}
		}
		@include respond('phone'){
			&:after {
				right: 15px;
			}
		}
	}
	.form-control{
		background: #fff;
		border-width: 0;
		color: #000;
		font-size: 18px;
		height: 60px;
		
		&:focus{
			border: none;
		}
		&:active{
			border: none;
		}
		&::placeholder{
			color: #000;
		}
		@include respond('tab-port'){
			font-size: 16px;
		}
	}
	.btn{
		padding: 10px 15px;
		i{
			font-size: 24px;
			color: var(--primary);
		}
		@include respond('tab-port'){
			i{
				font-size: 20px;
			}
		}
		@include respond('phone'){
			i{
				font-size: 16px;
			}
		}
	}	
	.input-group{
		align-items: center;
		flex-wrap: unset;
		margin: 0;
		.form-control{
			&::placeholder{
				color: #777;
			}
			@include respond('phone'){
				padding: 0 10px;
			}
		}
	}
	@include respond('tab-port'){
		padding: 10px;
		margin-top: -30px;
	}
	@include respond('phone-land'){
		padding: 0px 15px;
		.row > * {
			border-right: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			&:last-child{
				border: 0;
			}
		}
	}	
}


.tag-list{
	text-align: center;
	font-weight: 500;
	a{
		position: relative;
		display: inline-block;
		font-size: 16px;
		color: var(--primary);
		margin: 0 10px;
		&.title{
			color: var(--title);			
		}
		@include respond('phone-land'){
			font-size: 14px;
			margin: 0 5px;
		}
	}
}

.bootstrap-select {
	.dropdown-toggle{
		.filter-option-inner-inner {
			font-weight: 500;
		}
	}
}

.job-filter{
	&.style-1{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;
		
		@include respond ('phone'){
			display: block;
			margin-bottom: 20px;
		}
	}
	&.style-2{
		    display: flex;
			justify-content: end;
			align-items: center;
			margin-bottom: 40px;
		.clearfix{
			margin: 0 10px;
		}
		@include respond ('tab-port'){
			justify-content: start;
			.clearfix{
				margin:  10px;
			}
		}
		@include respond ('phone'){
			display: block;
			margin-bottom: 20px;
		}
	}
}

.select-drop-2{
	.select-drop-btn-2{
		background-color: transparent !important;
		color: var(--title);
		font-size: 18px;
		font-weight: 500;
		height: 60px;
		line-height: 60px;
		padding: 0 10px 0 40px;
		border: 0 !important;
		justify-content: space-between;
		align-items: center;
		display: flex;
		i{
			font-weight: 600;
			font-size: 14px;
			color: var(--primary);
		}
	}
	.dropdown-menu{
		.dropdown-item {
			&:first-child {
				background-color: var(--primary);
				color: $white;
			}
		}
	}
}