.contact-bx-wrapper{
	box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius-base);
    margin: -60px 150px 0;
    background-color: #fff;
    padding: 80px;
	position: relative;
	z-index: 1;
	
	label {
		font-size: 18px;
		display: block;
	}
	.form-control {
		height: 60px;
		border-bottom: 2px solid #EBE5DB;
		color: var(--title);
		background: #fff;
		border-width: 0 0 2px 0;
		padding: 10px 0;
		font-weight: 700;
		border-radius: 0;
		@include transitionSlow;
		&::placeholder{
			color: var(--title);
		}
		&:focus,
		&:active,
		&:hover{
			border-bottom: 2px solid var(--primary);
		}
	}
	textarea.form-control{
		height: 120px;
		color: var(--title);
	    padding: 15px 0;
		
		&::placeholder{
			color: var(--title);
		}
	}
}
.map-iframe {
	&.style-1{
		height: 600px;
		margin-top: -140px;
		
		iframe{
			width: 100%;
			height: 100%;
		}
		@include respond('laptop'){
			margin-top: -100px;
		}
		@include respond('tab-port'){
			height: 500px;
			margin-top: -70px;
		}
		@include respond('phone-land'){
			height: 400px;
		}
		@include respond('phone'){
			height: 250px;
		}
	}
}
.contact-info{
	&.style-1{
		margin-bottom: 30px;
		margin-right: 100px;
		margin-top: -500px;
		max-width: 900px;
		
		@include respond('tab-port'){
			margin-right: 30px;
			margin-left: 30px;
			margin-top: -100px;
		}
		@include respond('phone-land'){
			margin-right: 0;
			margin-left: 0;
			margin-top: 30px;			
		}
		@include respond('phone'){
			.title {
				font-size: 24px;
			}
		}
	}
	&.style-2{
		margin-left: auto;
		
		@include respond('tab-port'){
			margin-top: -40px;
		}
		@include respond('phone'){
			margin-top: -20px;
		}
	}
}
