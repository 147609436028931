.dz-card{
	&.style-7 {
		position: relative;
		border-radius: var(--border-radius-base);
		overflow: hidden;
		background-color: #003B4A;
		height: 100%;
		background-blend-mode: luminosity;
		min-height: 600px;
		background-size: cover;
		object-fit: cover;
	
		&:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: -moz-linear-gradient(top,  rgba(0,59,74,0) 0%, #003B4A 100%);
			background: -webkit-linear-gradient(top,  rgba(0,59,74,0) 0%, #003B4A 100%);
			background: linear-gradient(to bottom,  rgba(0,59,74,0) 0%, #003B4A 100%);
			opacity: 0.9;
		}
		
		.dz-media {
			height: 100%;
			position: relative;
			
			img {
				filter: grayscale(0.1);
				height: 100%;
				object-fit: cover;
				@include transitionSlow;
			}
		}
		.dz-info{
			padding: 40px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			
			.dz-meta{
				margin-bottom: 0;
				ul{
					li{
						position: relative;
						&.dz-user{
							color: var(--title);
							font-weight: 500;
							color: #fff;
							span{
								color: var(--secondary);
							}
							i{
								color: var(--secondary);
							}
						}
						&.dz-date{
							color: var(--title);				
							font-weight: 500;
							color: #fff;
							i{
								color: var(--secondary);
							}
						}
						&:not(:last-child):after{
							content: "";
							height: 6px;
							width: 6px;
							position: absolute;
							right: -10%;
							top: 50%;
							border-radius: 50%;
							background-color: var(--primary);
							transform: translate(0%, -50%);
						}
					}
				}
			}
		}
		.dz-category {
			margin-bottom: 15px;
			display: inline-flex;
			.dz-badge-list{
				position: absolute;
				top: 40px;
				left: 40px;
				z-index: 1;
				li {
					.dz-badge{
						background-color: var(--secondary);
						color: var(--title);
						font-weight: 500;
						border-radius: var(--border-radius-base);
						font-size: 15px;
						text-transform: uppercase;
						display: block;
						&:hover{
							background-color: white;
							color: var(--primary);					
						}
					}
				}
			}
		}
		
		&:hover {
			.dz-media {
				img {
					transform: rotate(5deg) scale(1.2);
				}
			}
		}
		@include respond('phone'){
			min-height: 450px;
			.dz-info{
				padding: 20px;
				 .dz-text {
					margin-bottom: 0;
				}
			}
			.dz-title {
				margin-bottom: 10px;
			}
			.dz-category {
				margin-bottom: 20px;
			}
		}
	
		&.overlay {
			background-color: #000;
			opacity: 0.9;
			z-index: 1;
			.dz-meta {
				li{
					.post-date {
						color: rgba(255, 255, 255, 0.8);
					}
				}
			}
		}
	}
}