.about-bx2{
	box-shadow: 0px 0 60px rgba(0, 0, 0, 0.09);
	z-index: 1;
	background-color: #fff;
	border-radius: var(--border-radius-base);
	overflow: hidden;
	
	.dz-media{
		height: 100%;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.dz-info{
		padding: 50px 50px;
		.signature{
			margin-top: 50px;
		}
		@include respond('phone-land'){
			padding: 35px;
		}
		@include respond('phone'){
			padding: 25px;
			.signature{
				margin-top: 20px;
				
				img{
					width: 120px;
				}
			}
		}
	}
}