// FILTER BUTTONS CSS
.site-filters {
    margin-bottom: 30px;
	ul {
		margin: 0;
		list-style: none;
	}
	li {
		display: inline-block;
		padding: 0;
		margin-bottom:3px;
		&.btn {
			box-shadow: none;
			-webkit-box-shadow: none;
			outline: none !important;
		}
		input {
			display: none;
		}
		a {
			margin: 0 5px 5px 0;
			display: block;
		}
		&.active{
			[class*="btn"] {
				color: #fff;
				background-color: #EFBB20;
			}
		}
		
	}
	&.center{
		text-align: center;
		ul {
			display: inline-block;
			margin: auto;
		}
		[class*="btn"] {
			display: inline-block;
			margin: 0 5px 10px;
			text-transform: uppercase;
			@include respond('phone'){
				margin: 0 1px 5px;
				padding: 5px 10px;
				font-size: 12px;
			}
		}
	}
	
	// Style 1
	&.style-1{
		margin-bottom: 20px;
		@include respond ('phone'){
			margin-bottom: 15px;
		}
		.filters{
			margin-right: -5px;
			margin-left: -5px;
			display: flex;
			flex-wrap: wrap;
			
			li{
				text-transform: capitalize!important;
				padding: 0 5px;
				margin-bottom: 10px;
				border: 0;
				border-radius:0;
				&:after{
					content:none;	
				}
				a{
					color: var(--primary);
					background-color: var(--rgba-primary-1);
					font-size: 14px;
					margin: 0;
					border-radius: var(--border-radius-base);
					padding: 14px 30px;
					font-weight: 500;
					text-transform: capitalize;
				}
				&.active{
					a{
						color: #fff;
						background-color: var(--primary);
					}
				}
			}
			@include respond ('phone-land'){
				justify-content: center;
			}
			@include respond ('phone'){
				margin-right: -3px;
				margin-left: -3px;
				
				li{
					padding: 0 3px;
					margin-bottom: 6px;
					a{
						font-size: 13px;
						padding: 10px 15px;
					}
				}
			}
		}
	}
}
