// Banner One
.banner-one{
	position:relative;
    align-items: center;
	overflow:hidden;
	z-index:1;
	
	&:after,
	&:before{
		content: "";
		position: absolute;
		height: 700px;
		width: 700px;
	}
	&:before {
		left: -300px;
		top: 0;
		z-index: -1;
		transform: translateY(-50%);
		background: -moz-radial-gradient(center, ellipse cover,  rgba(255,116,104 , 0.1) 30%,  rgba(255,116,104 , 0.1) 35%,  rgba(255,116,104 ,0) 76%,  rgba(255,116,104 ,0) 96%);
		background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,116,104 , 0.1) 30%,  rgba(255,116,104 , 0.1) 35%,  rgba(255,116,104 ,0) 76%,  rgba(255,116,104 ,0) 96%);
		background: radial-gradient(ellipse at center, rgba(255,116,104 , 0.1) 30%,  rgba(255,116,104 , 0.1) 35%,  rgba(255,116,104 ,0) 76%,  rgba(255,116,104 ,0) 96%);
		
	}
	&:after {
		right: 0;
		top: 0;
		background: -moz-linear-gradient(60deg,  rgba(255,116,104 ,0) 27%,  rgba(255,116,104 ,0) 44%,  rgba(255,116,104 , 0.1) 100%);
		background: -webkit-linear-gradient(60deg,  rgba(255,116,104 ,0) 27%,  rgba(255,116,104 ,0) 44%,  rgba(255,116,104 , 0.1) 100%);
		background: linear-gradient(60deg,  rgba(255,116,104 ,0) 27%,  rgba(255,116,104 ,0) 44%,  rgba(255,116,104 , 0.1) 100%);
	}
	
	@include custommq($max: 1199px) {
		height: 700px;
	}
	@include respond('tab-port'){
		padding-top: 60px;
		height: auto;
	}
	@include respond('phone'){
		padding-top: 50px;
	}
	
	.banner-content{
		padding-right: 100px;
		@include respond('tab-port'){
			padding-right: 0;			
		}
		h1{
			line-height: 1.2;
			font-size: 45px;
			margin-bottom: 20px;
			@include respond('laptop'){
				font-size:45px;
			}
			@include custommq($max: 1199px) {
				font-size: 37px;
			}
			@include respond('tab-port'){
				font-size: 29px;
			}
			@include respond('phone-land'){
				font-size:29px;
			}
			@include respond('phone'){
				font-size:24px;
			}
		}
		.sub-title{
			position: relative;
			margin-left: 30px;
			margin-bottom: 10px;
			&:before{
				content: "";
				position: absolute;
				top: 50%;
				left: -30px;
				background-color: var(--primary);
				width: 20px;
				height: 3px;
				border-radius: var(--border-radius-base);
				transform: translate(0, -50%);
			}
		}
		p{
			font-size:18px;	
			margin-bottom: 40px;
			margin-right: auto;
			max-width: 730px;
			@include respond('tab-port'){
				font-size:14px;
				margin-bottom:20px;
			}
			@include respond('phone'){
				font-size:14px;
			}
		}
		@include respond('tab-port'){
			padding-bottom:50px;
		}
		.btn-secondary{
			box-shadow: 0px 25px 50px 0px rgba(255, 116, 104, 0.36);
		}
	}
	.banner-media{
		position:relative;
		text-align:center;
		z-index: 1;
		margin-right: -50px;
		margin-left: -30px;
		
		.main-img {
			width:100%;
			height:100%;
		}
		
		&:before,
		&:after{
			content: "";
			position: absolute;
			border-radius: 25px;
			transform: skew(-15deg);
			z-index: -1;
		}
		&:before{
			background: rgb(27,130,113);
			background: -moz-linear-gradient(left,  var(--primary) 0%, var(--rgba-primary-8) 100%);
			background: -webkit-linear-gradient(left,  var(--primary) 0%,var(--rgba-primary-8) 100%);
			background: linear-gradient(to right,  var(--primary) 0%,var(--rgba-primary-8) 100%);
			height: 200px;
			width: 270px;
			left: 20px;
			top: 60px;
		}
		&:after{
			background: rgb(255,116,104);
			background: -moz-linear-gradient(left,  rgba(255,116,104,1) 0%, rgba(255,92,78,1) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,116,104,1) 0%,rgba(255,92,78,1) 100%);
			background: linear-gradient(to right,  rgba(255,116,104,1) 0%,rgba(255,92,78,1) 100%);
			height: 380px;
			width: 340px;
			right: -50px;
			bottom: 30px;
		}
		.dz-media{
			width: 125px;
			height: 125px;
			position: absolute;
			left: -30%;
			bottom: 20%;
			transform: translateX(50%);
			z-index: 1;
			.main-shape{
				width: 100%;
				height: 100%;
			}
		}
	}
	.dz-shape{
		.shape-1{
			width: 70px;
			position: absolute;
			right: 47%;
			bottom: 14%;
		}
		.shape-2{
			width: 50px;
			position: absolute;
			left: 2%;
			top: 10%;
		}
		.shape-3{
			width: 70px;
			position: absolute;
			left: 10%;
			bottom: 25%;
		}
		.shape-4{
			width: 50px;
			position: absolute;
			right: 15%;
			top: 30%;
		}
		
	}
	.banner-inner{
		position: relative;
		height: 740px;
		z-index: 99;
		align-items: flex-end;
		
		&:before{
			content: "";
			position: absolute;
			height: 700px;
			width: 700px;
			top: 50%;
			left: 80%;
			transform: translate(-50%, -50%);
			background: -moz-radial-gradient(center, ellipse cover,  rgba(64,186,212,0.1) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-radial-gradient(center, ellipse cover,  rgba(64,186,212,0.1) 0%,rgba(255,255,255,0) 100%);
			background: radial-gradient(ellipse at center,  rgba(64,186,212,0.1) 0%,rgba(255,255,255,0) 100%);
			z-index: -1;
		}
		@include respond('tab-port'){
			height: auto;
			align-items: center;
		}
	}
	
	@include respond('laptop'){
		.banner-media {
			margin-right: -10px;
			&:before{				
				height: 180px;
				width: 240px;
			}
			&:after{				
				height: 330px;
				width: 260px;
				right: -20px;
				bottom: 10px;
			}
		}	
		.dz-shape{
			.shape-3 {
				left: 5%;
			}
		}
	}
	@include respond('tab-land'){
		.banner-media {
			margin-right: 0px;
			&:before{				
				height: 160px;
				width: 220px;
			}
			&:after{				
				height: 290px;
				width: 250px;
				right: 5px;
				bottom: 30px;
			}
		}	
		.dz-shape{
			.shape-3 {
				left: 0%;
			}
			.shape-4{
				right: 5%;
			}
		}
	}
	@include custommq ($max:1199px){
		.banner-media {
			&:before{				
				height: 140px;
				width: 180px;
			}
			&:after{				
				height: 230px;
				width: 210px;
				right: -5px;
				bottom: 20px;
			}
		}	
	}
	@include respond('tab-port'){
		.banner-media {
			.main-img {
				width: 350px;
				height: auto;
			}
			&:before{		
				height: 120px;
				width: 120px;
				left: 22%;
				top: 40px;
				border-radius: 10px;
			}
			&:after{				
				height: 100px;
				width: 100px;
				right: 70%;
				bottom: 20px;
				border-radius: 10px;
			}
			.dz-media{
				width: 80px;
				height: 80px;
				left: 65%;
			}
		}
		.dz-shape{
			.shape-1{
				z-index: 999;
			}
			.shape-3{
				z-index: 999;
			}
		}
	}
	@include respond('phone-land'){
		.banner-media {
			.main-img {
				width: 300px;
				height: auto;
			}
			&:before{		
				height: 160px;
				width: 210px;
				left: 15%;
				top: 60px;
			}
			&:after{				
				height: 220px;
				width: 220px;
				right: 15%;
				bottom: 20px;
			}
		}
	}
	@include respond('phone'){
		.banner-media {
			.main-img {
				width: 250px;
				height: auto;
			}
			&:before{		
				height: 130px;
				width: 145px;
				left: 20%;
				top: 60px;
			}
			&:after{				
				height: 180px;
				width: 180px;
				right: 10%;
				bottom: 20px;
			}			
			.dz-media{
				width: 80px;
				height: 80px;
			}
		}	
	}
}
.banner-clients-swiper{
	padding: 60px 0;
    margin-left: 0;
    width: 80%;
   .swiper-wrapper{
		align-items: center;
		
		.clients-logo{
			cursor: pointer;
			img{
				margin: auto;
				@include transitionSlow;
			}
			.logo-main{
				opacity: 0.2;
			}
			&:hover{
				.logo-main{
					opacity: 1;
				}
			}
		}
	}
	@include respond('tab-port'){
		display: none;
	}
}